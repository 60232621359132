<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    total:{
      type : Number,
      default: 0
    },
    page:{
      type : Number,
      default: 0
    },
    limit:{
      type : Number,
      default: 20
    },
    pageSizes:{
      type : Array,
      default: () => [10, 20, 30, 50]
    },
    layout:{
      type : String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background:{
      type : Boolean,
      default: true
    },
    hidden:{
      type : Boolean,
      default: false
    },
  },  
  computed:{
    currentPage:{
      get:function(){  
        return this.page;
      },
		  set:function(newVal){ 
			 this.$emit("update:page", newVal);
		  }
    },
    pageSize:{
      get:function(){  
        return this.limit
      },
		  set:function(newVal){ 
			  this.$emit("update:limit", newVal);
		  }
    },
  },
  methods: {
    
    handleSizeChange(val) {
      this.$emit("pagination", { page: this.currentPage, limit: val });      
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });    
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
  text-align:right
}
.pagination-container.hidden {
  display: none;
}
</style>
<style>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background: #FB3A4E;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: #FB3A4E;
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  color:#fff;
}
.el-select-dropdown__item.selected{
  color: #FB3A4E;
}
.el-select .el-input.is-focus .el-input__inner{
  border-color: #FB3A4E;
}
.el-select .el-input__inner:focus{
  border-color: #FB3A4E;
}
</style>
