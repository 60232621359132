<template>
  <div class="home">
    <Header :type="'-1'" />
    <div class="search-header">
      <div class="content-box">
        <div class="box">
          <div class="content">
            <el-input
              v-model="query.title"
              placeholder="请输入搜索内容"
              @keydown.native.enter="goSearch"
            ></el-input>
            <div class="search-btn" @click="goSearch">搜索</div>
          </div>
          <div class="hots" v-if="query.title">
            <div class="hots-title">
              共找到<span class="hots-span">“ {{ query.title }} ”</span>
              相关内容 {{ list.length }}条
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="search-course">
      <div>
        <div class="tab-list">
          <span
            v-for="item in tabList"
            :key="item.id"
            :class="type == item.id ? 'active' : ''"
            class="hover"
            @click="changeTabs(item.id)"
            >{{ item.name }}</span
          >
        </div>
        <div class="c-content">
          <Active
            :activeList="list"
            :show="false"
            v-if="list.length > 0"
            :fullpath="'active'"
            :type="type"
          />
          <Pagination
            v-show="query.total > 0"
            :total="query.total"
            :page="query.page"
            :limit="query.limit"
            @pagination="pagination"
          />
          <NoData v-if="list.length <= 0" imgType="course" hint="暂无内容" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header-one/index.vue";
import Pagination from "@/components/pagination.vue";
import NoData from "@/components/no-data.vue";
import Active from "@/components/home/active.vue";
import { getActiveList, getClassList } from "@/api/home.js";
export default {
  components: {
    Header,
    NoData,
    Pagination,
    Active,
  },
  data() {
    return {
      tabList: [
        {
          id: 1,
          name: "活动",
        },
        {
          id: 2,
          name: "兴趣班",
        },
      ],
      list: [],
      query: {
        title: "",
        limit: 40,
        page: 1,
        total: 0,
      },
      type: 1,
    };
  },
  methods: {
    getList() {
      const api = this.type == 1 ? getActiveList : getClassList;
      api({
        ...this.query,
        type: this.type,
      }).then((res) => {
        if (res) {
          this.list = res.data.list;
          this.query.total = res.data.total;
        }
      });
    },

    pagination(page) {
      this.query.page = page.page;
      this.query.limit = page.limit;
      this.getList();
    },
    changeTabs(id) {
      this.type = id;
      this.query.page = 1;
      this.query.limit = 40;
      this.query.total = 0;
      this.getList();
    },

    goSearch() {
      this.query.page = 1;
      this.query.limit = 40;
      this.query.total = 0;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search-header {
  width: 100%;
  background-color: #fff;
  .content-box {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
    .box {
      width: 640px;
      display: flex;
      flex-flow: column;
      margin: 0 auto;
      .content {
        display: flex;
        .search-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 100px;
          height: 40px;
          background: #fb3a4e;
          border-radius: 20px;
          color: #fff;
          font-size: 16px;
          margin-left: 15px;
        }
        .el-input {
          border: 1px solid #fb3a4e;
          border-radius: 20px;
          overflow: hidden;
          outline: none;
          /deep/ .el-input__inner {
            border-radius: 20px;
            border: none;
          }
        }
      }
      .hots {
        width: 521px;
        padding-top: 15px;
        &-title {
          width: 100%;
          color: #cccccc;
          font-size: 12px;
          text-align: left;
        }
        .hots-span {
          color: #f5394c;
        }
      }
    }
  }
}
.search-course {
  background: #f8f8f8;
  padding: 10px 0 30px;
  > div {
    width: 1200px;
    margin: 0px auto;
    background: #fff;
    padding: 0 20px;
  }
  .tab-list {
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #eeeeee;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span:first-child {
      margin-right: 25px;
    }
    .active {
      font-weight: 500;
      color: #f5394c;
      background: rgba(245, 57, 76, 0.1);
      padding: 0 16px;
      height: 35px;
      line-height: 35px;
      border-radius: 18px;
    }
  }
}
</style>
