<!--
 * @Author: your name
 * @Date: 2021-06-25 15:46:05
 * @LastEditTime: 2021-07-31 18:13:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \study-pc\components\noData\index.vue
-->

<template>
    <div>
        <div class="img-box">
            <img src="@/assets/mine/nodata/noData.png" alt="">
            <div class="img-hint">{{hint}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        hint:{
            type:String,
            default:'暂无数据~'
        }
    }
}
</script>
<style lang="less" scoped>
.img-box{
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    padding: 50px 0;
    img{
        width: 464px;
        height: 240px;
    }
  .img-hint{
      padding-top: 20px;
      color: #999999;
      font-size: 14px;
  }
}

</style>